<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="mt-3">
      <b-card-header header-tag="header" role="tab">
        <div v-b-toggle.license class="d-flex justify-content-between">
          <div class="h6">
            <strong> License </strong>
          </div>

          <span class="when-opened">
            <i class="fa fa-chevron-up" aria-hidden="true"></i
          ></span>
          <span class="when-closed">
            <i class="fa fa-chevron-down" aria-hidden="true"></i
          ></span>
        </div>
      </b-card-header>
      <b-collapse id="license" role="tabpanel">
        <b-card-body>
          <div class="my-4">
            <div class="mt-3">
              <strong> Mode: </strong>
              {{ this.sc_mode | humanizeText }}
            </div>
            <div class="mt-3" v-if="this.sc_mode == 'SC_VMS'">
              <strong> Version: </strong>
              {{ this.$store.state.app_version }}
            </div>
            <div class="d-flex">
              <strong> Status: </strong>
              <div class="d-flex align-items-center">
                <div
                  class="status-indicator mx-1"
                  :class="[active == 1 ? 'status-online' : 'status-offline']"
                ></div>
                {{ active ? "Active" : "Inactive" }}
              </div>
            </div>
            <div>
              <strong> Expires: </strong>
              {{ this.exp }}
            </div>
            <div>
              <strong> Country Code: </strong>
              {{ this.country_code }}
            </div>
            <div>
              <strong> User Usage </strong>
              <br />
              <div>
                <ul v-if="max_users != undefined">
                  <!-- <li>Total : {{ max_users.total }}</li> -->
                  <li>
                    Operator : {{ total_operators + "/" + max_users.operator }}
                  </li>
                  <li>Kiosk : {{ total_kiosks + "/" + max_users.kiosk }}</li>
                  <li>Host : {{ total_hosts + "/" + max_users.host }}</li>
                  <li>Others : {{ total_others + "/" + max_others }}</li>
                </ul>
              </div>
            </div>
          </div>
          <!--          <b-card-text>-->
          <!--            <div class="d-flex">-->
          <!--              <div class="w-5"><strong>Mode</strong></div>-->
          <!--              <div class="mx-2">:</div>-->
          <!--              <div>{{ this.sc_mode | humanizeText }}</div>-->
          <!--            </div>-->

          <!--            <div class="d-flex">-->
          <!--              <div class="w-5"><strong>Status</strong></div>-->
          <!--              <div class="mx-2">:</div>-->
          <!--              <div class="d-flex align-items-center">-->
          <!--                <div-->
          <!--                  class="status-indicator mr-1"-->
          <!--                  :class="[active == 1 ? 'status-online' : 'status-offline']"-->
          <!--                ></div>-->
          <!--                {{ active ? "Active" : "Inactive" }}-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <div class="d-flex">-->
          <!--              <div class="w-5"><strong>Expires</strong></div>-->
          <!--              <div class="mx-2">:</div>-->
          <!--              <div>{{ this.exp }}</div>-->
          <!--            </div>-->
          <!--          </b-card-text>-->
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
let utils = require("@/assets/global/js/utils.js");

export default {
  data() {
    return {
      exp: "",
      sc_cam: "",
      sc_mode: "",
      country_code: null,
      max_users: null,
      max_others: 0,
      total_users: 0,
      total_operators: 0,
      total_kiosks: 0,
      total_hosts: 0,
      total_others: 0,
      active: false
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  mounted: function () {
    this.getCurrentUser != null
      ? (this.getLicenseData(), this.getUserStatistics())
      : null;
  },
  methods: {
    getLicenseData() {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/license/info/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          for (const url in data?.result) {
            this.max_users = data?.result?.[url].SC_VMS.max_users;
            this.max_others = data?.result?.[url].SC_VMS.max_users.others;
            this.sc_mode = data?.result?.[url]?.["sc_mode"];
            this.country_code = data?.result?.[url]?.["country_code"];
            let exp = moment(data?.result?.[url]?.["exp"]);
            this.exp = exp.format("DD/MM/YYYY HH:mm:ss");
            let now = moment();
            let diffSec = exp.diff(now);
            this.active = diffSec > 0 ? (this.active = true) : false;
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err?.detail,
            message_type: "danger"
          });
        });
    },

    getUserStatistics() {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/users/stats/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          console.log("user statistics", data);
          this.total_operators = data?.vms_operator;
          this.total_kiosks = data?.vms_kiosk;
          this.total_hosts = data?.vms_host;
          this.total_others = data?.others;
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    }
  }
};
</script>

<style lang="scss">
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.status-online {
    background-color: $success;
  }

  &.status-offline {
    background-color: $danger;
  }
}
</style>

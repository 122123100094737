<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div
            v-b-toggle.internal_services
            class="d-flex justify-content-between"
          >
            <div class="h6">
              <strong> P1 Setting </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="internal_services" role="tabpanel">
          <b-card-body>
            <!--            <div class="d-flex justify-content-end my-2">-->
            <!--              <b-button-->
            <!--                variant="success"-->
            <!--                size="sm"-->
            <!--                @click="handleAddP1"-->
            <!--                :disabled="p1ServicesTableItems.length > 0"-->
            <!--              >-->
            <!--                <i class="fa fa-plus mr-1"></i>Add-->
            <!--              </b-button>-->
            <!--              <b-button size="sm">-->
            <!--                <i class="fas fa-sync" @click="getEpP1Services"></i>-->
            <!--              </b-button>-->
            <!--            </div>-->

            <b-table
              striped
              class="mt-2"
              :items="p1ServicesTableItems"
              :fields="p1ServicesTableFields"
              :busy="p1ServicesTableIsBusy"
              stacked="lg"
            >
              <template #cell(actions)="row">
                <b-row>
                  <b-button
                    variant="secondary"
                    size="sm"
                    v-b-modal.form-update-user
                    v-b-tooltip.hover
                    title="Update"
                    class="mx-1"
                    @click="handleEditP1(row.item)"
                  >
                    <i class="fa fa-edit"></i>
                  </b-button>

                  <b-button
                    variant="secondary"
                    size="sm"
                    v-b-tooltip.hover
                    title="Download Certificate"
                    class="mr-1"
                    @click="handleDownloadCer(row.item)"
                  >
                    <i class="fas fa-download"></i>
                  </b-button>

                  <b-button
                    variant="secondary"
                    size="sm"
                    v-b-tooltip.hover
                    title="Manage UDF"
                    class="mr-1"
                    @click="handleEditUdf(row.item)"
                  >
                    <i class="fa fa-user-cog"></i>
                  </b-button>

                  <b-button
                    variant="danger"
                    size="sm"
                    v-b-tooltip.hover
                    title="Delete"
                    @click="confirmDeleteMonitorService(row.item)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </b-button>
                </b-row>
              </template>
            </b-table>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <!-- PI modal -->
    <b-modal
      id="p1_modal"
      centered
      :title="p1ModalTitle"
      @ok="onSubmitP1Form"
      @cancel="resetP1Form"
    >
      <b-form>
        <!--        <pre>-->
        <!--          {{p1Form}}-->
        <!--        </pre>-->

        <p>P1 URL</p>
        <b-input-group>
          <b-form-input
            v-model="p1Form.hostname"
            required
            @change="autoFillP1Form"
          ></b-form-input>
          <template #prepend>
            <b-form-select v-model="p1Form.protocol">
              <b-form-select-option value="https://"
                >https://</b-form-select-option
              >
              <b-form-select-option value="http://"
                >http://</b-form-select-option
              >
            </b-form-select>
          </template>
        </b-input-group>

        <!--        <b-form-group label="Url" label-for="p1Form.url">-->
        <!--          <b-input-group>-->
        <!--            <b-form-input-->
        <!--              id="p1Form.url"-->
        <!--              name="p1Form.url"-->
        <!--              v-model="$v.p1Form.url.$model"-->
        <!--              aria-describedby="p1Form.url.feedback"-->
        <!--            ></b-form-input>-->
        <!--            <b-form-invalid-feedback id="p1Form.url.feedback"-->
        <!--              >Please enter a valid URL.-->
        <!--            </b-form-invalid-feedback>-->
        <!--            <b-input-group-append>-->
        <!--              <b-button-->
        <!--                variant="info"-->
        <!--                @click="autoFillP1Form"-->
        <!--                :disabled="!enableAutoFillButton($v.p1Form.url)"-->
        <!--                >Auto Fill-->
        <!--              </b-button>-->
        <!--            </b-input-group-append>-->
        <!--          </b-input-group>-->
        <!--        </b-form-group>-->

        <div class="accordion" role="tablist">
          <hr color="gray" />

          <div>
            <div v-b-toggle.p1Form_evim class="d-flex justify-content-between">
              <div class="h6">
                <strong> Evim Section </strong>
              </div>

              <span class="when-opened">
                <i class="fa fa-chevron-down" aria-hidden="true"></i
              ></span>
              <span class="when-closed">
                <i class="fa fa-chevron-up" aria-hidden="true"></i
              ></span>
            </div>

            <b-collapse id="p1Form_evim" role="tabpanel">
              <b-form-group label="Evim Url" label-for="p1Form.meta.evim.url">
                <b-form-input
                  id="p1Form.meta.evim.url"
                  name="p1Form.meta.evim.url"
                  v-model="$v.p1Form.meta.evim.url.$model"
                  :state="validateState($v.p1Form.meta.evim.url)"
                  aria-describedby="p1Form.meta.evim.url.feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="p1Form.meta.evim.url.feedback"
                  >Please enter a valid URL.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="Evim API Base URL"
                label-for="p1Form.meta.evim.params.api_params.base_url"
              >
                <b-form-input
                  id="p1Form.meta.evim.params.api_params.base_url"
                  name="p1Form.meta.evim.params.api_params.base_url"
                  v-model="
                    $v.p1Form.meta.evim.params.api_params.base_url.$model
                  "
                  :state="
                    validateState(
                      $v.p1Form.meta.evim.params.api_params.base_url
                    )
                  "
                  aria-describedby="p1Form.meta.evim.params.api_params.base_url.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.evim.params.api_params.base_url.feedback"
                  >Please enter a valid URL.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="Evim Reader Timeout"
                label-for="p1Form.meta.evim.params.reader_timeout"
              >
                <b-form-input
                  id="p1Form.meta.evim.params.reader_timeout"
                  name="p1Form.meta.evim.params.reader_timeout"
                  v-model="$v.p1Form.meta.evim.params.reader_timeout.$model"
                  :state="
                    validateState($v.p1Form.meta.evim.params.reader_timeout)
                  "
                  aria-describedby="p1Form.meta.evim.params.reader_timeout.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.evim.params.reader_timeout.feedback"
                  >Please enter a valid integer.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="Evim Connect Timeout"
                label-for="p1Form.meta.evim.params.connect_timeout"
              >
                <b-form-input
                  id="p1Form.meta.evim.params.connect_timeout"
                  name="p1Form.meta.evim.params.connect_timeout"
                  v-model="$v.p1Form.meta.evim.params.connect_timeout.$model"
                  :state="
                    validateState($v.p1Form.meta.evim.params.connect_timeout)
                  "
                  aria-describedby="p1Form.meta.evim.params.connect_timeout.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.evim.params.connect_timeout.feedback"
                  >Please enter a valid integer.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-collapse>
          </div>

          <hr color="gray" />

          <div no-body>
            <div v-b-toggle.p1Form_rest class="d-flex justify-content-between">
              <div class="h6">
                <strong> REST Section </strong>
              </div>

              <span class="when-opened">
                <i class="fa fa-chevron-down" aria-hidden="true"></i
              ></span>
              <span class="when-closed">
                <i class="fa fa-chevron-up" aria-hidden="true"></i
              ></span>
            </div>

            <b-collapse id="p1Form_rest" role="tabpanel">
              <b-form-group label="REST URL" label-for="p1Form.meta.rest.url">
                <b-form-input
                  id="p1Form.meta.rest.url"
                  name="p1Form.meta.rest.url"
                  v-model="$v.p1Form.meta.rest.url.$model"
                  :state="validateState($v.p1Form.meta.rest.url)"
                  aria-describedby="p1Form.meta.rest.url.feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="p1Form.meta.rest.url.feedback"
                  >Please enter a valid URL.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="REST API Params Retries"
                label-for="p1Form.meta.rest.params.api_params.retries"
              >
                <b-form-input
                  id="p1Form.meta.rest.params.api_params.retries"
                  name="p1Form.meta.rest.params.api_params.retries"
                  v-model="$v.p1Form.meta.rest.params.api_params.retries.$model"
                  :state="
                    validateState($v.p1Form.meta.rest.params.api_params.retries)
                  "
                  aria-describedby="p1Form.meta.rest.params.api_params.retries.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.rest.params.api_params.retries.feedback"
                  >Please enter a valid integer.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="REST API Params Timeout"
                label-for="p1Form.meta.rest.params.api_params.timeout"
              >
                <b-form-input
                  id="p1Form.meta.rest.params.api_params.timeout"
                  name="p1Form.meta.rest.params.api_params.timeout"
                  v-model="$v.p1Form.meta.rest.params.api_params.timeout.$model"
                  :state="
                    validateState($v.p1Form.meta.rest.params.api_params.timeout)
                  "
                  aria-describedby="p1Form.meta.rest.params.api_params.timeout.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.rest.params.api_params.timeout.feedback"
                  >Please enter a valid integer.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="REST API Params Base URL"
                label-for="p1Form.meta.rest.params.api_params.base_url"
              >
                <b-form-input
                  id="p1Form.meta.rest.params.api_params.base_url"
                  name="p1Form.meta.rest.params.api_params.base_url"
                  v-model="
                    $v.p1Form.meta.rest.params.api_params.base_url.$model
                  "
                  :state="
                    validateState(
                      $v.p1Form.meta.rest.params.api_params.base_url
                    )
                  "
                  aria-describedby="p1Form.meta.rest.params.api_params.base_url.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.rest.params.api_params.base_url.feedback"
                  >Please enter a valid URL.
                </b-form-invalid-feedback>
              </b-form-group>

              <!--              <b-form-group-->
              <!--                label="REST API Params SSL Verify"-->
              <!--                label-for="p1Form.meta.rest.params.api_params.ssl_verify"-->
              <!--              >-->
              <!--                <b-form-input-->
              <!--                  id="p1Form.meta.rest.params.api_params.ssl_verify"-->
              <!--                  name="p1Form.meta.rest.params.api_params.ssl_verify"-->
              <!--                  v-model="-->
              <!--                    $v.p1Form.meta.rest.params.api_params.ssl_verify.$model-->
              <!--                  "-->
              <!--                  :state="-->
              <!--                    validateState(-->
              <!--                      $v.p1Form.meta.rest.params.api_params.ssl_verify-->
              <!--                    )-->
              <!--                  "-->
              <!--                  aria-describedby="p1Form.meta.rest.params.api_params.ssl_verify.feedback"-->
              <!--                ></b-form-input>-->
              <!--                <b-form-invalid-feedback-->
              <!--                  id="p1Form.meta.rest.params.api_params.ssl_verify.feedback"-->
              <!--                  >This field is required.-->
              <!--                </b-form-invalid-feedback>-->
              <!--              </b-form-group>-->
            </b-collapse>
          </div>

          <hr color="gray" />

          <div no-body>
            <div
              v-b-toggle.p1Form_xsocket
              class="d-flex justify-content-between"
            >
              <div class="h6">
                <strong> xSocket Section </strong>
              </div>

              <span class="when-opened">
                <i class="fa fa-chevron-down" aria-hidden="true"></i
              ></span>
              <span class="when-closed">
                <i class="fa fa-chevron-up" aria-hidden="true"></i
              ></span>
            </div>

            <b-collapse id="p1Form_xsocket" role="tabpanel">
              <b-form-group
                label="xSocket URL"
                label-for="p1Form.meta.xsocket.url"
              >
                <b-form-input
                  id="p1Form.meta.xsocket.url"
                  name="p1Form.meta.xsocket.url"
                  v-model="$v.p1Form.meta.xsocket.url.$model"
                  :state="validateState($v.p1Form.meta.xsocket.url)"
                  aria-describedby="p1Form.meta.xsocket.url.feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="p1Form.meta.xsocket.url.feedback"
                  >Please enter a valid URL.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="xSocket Api Params Base URL"
                label-for="p1Form.meta.xsocket.params.api_params.base_url"
              >
                <b-form-input
                  id="p1Form.meta.xsocket.params.api_params.base_url"
                  name="p1Form.meta.xsocket.params.api_params.base_url"
                  v-model="
                    $v.p1Form.meta.xsocket.params.api_params.base_url.$model
                  "
                  :state="
                    validateState(
                      $v.p1Form.meta.xsocket.params.api_params.base_url
                    )
                  "
                  aria-describedby="p1Form.meta.xsocket.params.api_params.base_url.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.xsocket.params.api_params.base_url.feedback"
                  >Please enter a valid URL.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="xSocket Api Params Service ID"
                label-for="p1Form.meta.xsocket.params.api_params.service_id"
              >
                <b-form-input
                  id="p1Form.meta.xsocket.params.api_params.service_id"
                  name="p1Form.meta.xsocket.params.api_params.service_id"
                  v-model="
                    $v.p1Form.meta.xsocket.params.api_params.service_id.$model
                  "
                  :state="
                    validateState(
                      $v.p1Form.meta.xsocket.params.api_params.service_id
                    )
                  "
                  aria-describedby="p1Form.meta.xsocket.params.api_params.service_id.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.xsocket.params.api_params.service_id.feedback"
                  >This field is required.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="xSocket Params Reader Timeout"
                label-for="p1Form.meta.xsocket.params.reader_timeout"
              >
                <b-form-input
                  id="p1Form.meta.xsocket.params.reader_timeout"
                  name="p1Form.meta.xsocket.params.reader_timeout"
                  v-model="$v.p1Form.meta.xsocket.params.reader_timeout.$model"
                  :state="
                    validateState($v.p1Form.meta.xsocket.params.reader_timeout)
                  "
                  aria-describedby="p1Form.meta.xsocket.params.reader_timeout.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.xsocket.params.reader_timeout.feedback"
                  >Please enter a valid integer.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="xSocket Params Connect Timeout"
                label-for="p1Form.meta.xsocket.params.connect_timeout"
              >
                <b-form-input
                  id="p1Form.meta.xsocket.params.connect_timeout"
                  name="p1Form.meta.xsocket.params.connect_timeout"
                  v-model="$v.p1Form.meta.xsocket.params.connect_timeout.$model"
                  :state="
                    validateState($v.p1Form.meta.xsocket.params.connect_timeout)
                  "
                  aria-describedby="p1Form.meta.xsocket.params.connect_timeout.feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="p1Form.meta.xsocket.params.connect_timeout.feedback"
                  >Please enter a valid integer.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-collapse>
          </div>

          <hr color="gray" />
        </div>

        <b-form-group
          label="Transaction Server URL"
          label-for="p1Form.meta.transaction_server.url"
        >
          <b-form-input
            id="p1Form.meta.transaction_server.url"
            name="p1Form.meta.transaction_server.url"
            v-model="$v.p1Form.meta.transaction_server.url.$model"
            :state="validateState($v.p1Form.meta.transaction_server.url)"
            aria-describedby="p1Form.meta.transaction_server.url.feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            id="p1Form.meta.transaction_server.url.feedback"
            >Please enter a valid URL.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-if="editP1Form" label="AES Key">
          <b-form-input
            v-model="p1Form.security.p1_aes_key"
            type="password"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- UDF modal -->
    <b-modal
      id="udf_modal"
      centered
      title="UDF Configurations"
      @ok="onSubmitUdfForm"
    >
      <b-form>
        <b-form-group
          v-for="(item, key) in udf_form"
          :key="'udf_form_group_' + key"
          :label="key"
          :label-for="'input_' + key"
        >
          <b-form-input
            :id="'input_' + key"
            :name="'input_' + key"
            v-model="$v.udf_form[key].$model"
            :state="validateState($v.udf_form[key])"
            :aria-describedby="'input_' + key + '.feedback'"
          ></b-form-input>
          <b-form-invalid-feedback :id="'input_' + key + '.feedback'"
            >Please replace space with "_" for your
            <span class="text-uppercase">{{
              key
            }}</span></b-form-invalid-feedback
          >
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  url,
  helpers,
  and
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import store from "../../store";
const FileDownload = require("js-file-download");

var validUrl = require("valid-url");
var isUrl = require("is-url");
const alpha = helpers.regex("alpha", /^\S*$/);

const urlValidation2 = (value) => {
  try {
    if (value) {
      if (validUrl.isUri(value) && isUrl(value)) {
        try {
          let { hostname } = new URL(value);

          if (hostname) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }

        // let array = value.split("//");
        //
        // if(array[1].includes(".")){
        //
        //   let array2 = array[1].split(".");
        //
        //   console.log("array2", array2)
        //   if(array2.length === 4){
        //     return true;
        //   }else{
        //     return false;
        //   }
        //
        // }else{
        //   return true;
        // }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export default {
  mixins: [validationMixin],
  data() {
    return {
      pageTitle: "Service Settings",

      /** service_setting **/
      setting: null,
      service_setting: {
        apiserver_url: null,
        websocket_url: null,

        vms_default_visit_access_type: null,
        vms_kiosk_company_banner: null,
        vms_kiosk_company_name: null
      },

      vms_default_visit_access_type_options: [
        { text: "Card Access", value: "card" },
        { text: "Dynamic QR Access", value: "dynamic_qr" },
        { text: "Static QR Access", value: "static_qr" },
        { text: "Face Access", value: "face" }
      ],

      /** API & Websocket **/
      apiForm: {
        apiserver_url: null,
        websocket_url: null
      },

      /** UDF **/
      editUdfForm: false,
      allUdfMapping: [],
      udf_form: {
        udF1: null,
        udF2: null,
        udF3: null,
        udF4: null,
        udF5: null,
        udF6: null,
        udF7: null,
        udF8: null,
        udF9: null,
        udF10: null
      },
      udfMappingId: null,
      acsUrl: null,

      /** P1 **/
      p1_url: null,
      p1ServicesTableItems: [],
      p1ServicesTableFields: [
        { key: "url", label: "URL" },
        { key: "actions", label: "Actions" }
      ],
      p1ServicesTableIsBusy: false,
      p1ModalTitle: null,
      editP1Form: false,
      p1Form: {
        url: null,
        hostname: null,
        protocol: null,
        group: "EP_P1",
        meta: {
          evim: {
            url: null,
            meta: {},
            params: {
              api_params: {
                base_url: null
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          rest: {
            url: null,
            meta: {},
            params: {
              api_params: {
                retries: 1,
                timeout: 8,
                base_url: null,
                ssl_verify: null
              }
            }
          },
          xsocket: {
            url: null,
            meta: {},
            params: {
              api_params: {
                base_url: null,
                service_id: "SC_EVA"
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          transaction_server: {
            url: null,
            meta: {},
            params: {}
          }
        },
        params: {
          api_params: {
            retries: 1,
            timeout: 8,
            base_url: null,
            ssl_verify: null
          }
        },
        active: true,
        security: {
          qr_key: "12345678",
          p1_aes_key: "88888888",
          qr_sequence:
            "aaaaaabbbbbbbbbbaaaaaaccccccccccccaaaaaaddddddddddddaaaaeaaaaaaa",
          qr_timezone: "Asia/Kuala_Lumpur"
        }
      },

      /** messaging **/
      allMessaging: {},
      messagingForm: {
        smtp_host: null,
        smtp_port: null,
        smtp_username: null,
        smtp_password: null,
        email_engine: null,
        aws_default_region: null,
        email_from_address: null,
        aws_access_key_id: null,
        aws_secret_access_key: null
      },
      EMAIL_ENGINE: null,
      SMTP_HOST: null,
      SMTP_PORT: null,
      SMTP_USERNAME: null,
      SMTP_PASSWORD: null,
      AWS_DEFAULT_REGION: null,
      EMAIL_FROM_ADDRESS: null,
      AWS_ACCESS_KEY_ID: null,
      AWS_SECRET_ACCESS_KEY: null,
      email_engine: [
        { value: "SMTP", text: "SMTP" },
        { value: "SES", text: "SES" }
      ]
    };
  },
  mounted() {
    this.getAllSettings();
    this.getEpP1Services();
    this.getAllMessaging();
  },
  watch: {
    p1Form: {
      deep: true,
      handler: function (newVal) {
        console.log("newVal");
        console.log(newVal);

        if (newVal.hostname) {
          console.log("p1Form newVal.hostname");
          console.log(newVal.hostname);
          //this.autoFillP1Form();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL",
      getWSServerURL: "session/getWSServerURL"
    })
  },
  validations: {
    p1Form: {
      // url: { urlValidation2 },
      group: { required },
      meta: {
        evim: {
          url: { required },
          meta: {},
          params: {
            api_params: {
              base_url: { required }
            },
            reader_timeout: { required },
            connect_timeout: { required }
          }
        },
        rest: {
          url: { required },
          meta: {},
          params: {
            api_params: {
              retries: { required },
              timeout: { required },
              base_url: { required },
              ssl_verify: { required }
            }
          }
        },
        xsocket: {
          url: { required },
          meta: {},
          params: {
            api_params: {
              base_url: { required },
              service_id: { required }
            },
            reader_timeout: { required },
            connect_timeout: { required }
          }
        },
        transaction_server: {
          url: { required },
          meta: {},
          params: {}
        }
      },
      params: {
        api_params: {
          retries: { required },
          timeout: { required },
          base_url: { required },
          ssl_verify: { required }
        }
      },
      active: true
    },

    udf_form: {
      udF1: {
        alpha
      },
      udF2: {
        alpha
      },
      udF3: {
        alpha
      },
      udF4: {
        alpha
      },
      udF5: {
        alpha
      },
      udF6: {
        alpha
      },
      udF7: {
        alpha
      },
      udF8: {
        alpha
      },
      udF9: {
        alpha
      },
      udF10: {
        alpha
      }
    },
    apiserver_url: null,
    websocket_url: null,
    apiForm: {
      apiserver_url: { urlValidation2 },
      websocket_url: { urlValidation2 }
    },
    /* kioskForm: {
      company_banner: { required },
      company_name: { required }
    }, */
    messagingForm: {
      smtp_host: { required },
      smtp_port: { required },
      smtp_username: { required },
      smtp_password: { required },
      email_engine: { required },
      aws_default_region: { required },
      email_from_address: { required },
      aws_access_key_id: { required },
      aws_secret_access_key: { required }
    },
    EMAIL_ENGINE: null,
    SMTP_HOST: null,
    SMTP_PORT: null,
    SMTP_USERNAME: null,
    SMTP_PASSWORD: null,
    AWS_DEFAULT_REGION: null,
    EMAIL_FROM_ADDRESS: null,
    AWS_ACCESS_KEY_ID: null,
    AWS_SECRET_ACCESS_KEY: null
  },
  methods: {
    /** form validation **/
    validateState(field) {
      const { $dirty, $error } = field;
      return $dirty ? !$error : null;
    },
    enableAutoFillButton(field) {
      const { $error } = field;
      return !$error;
    },

    /** Settings **/
    async getAllSettings() {
      try {
        let API_URL = this.getAPIServerURL + "/settings/all/";
        const client = this.$root.getAjaxFetchClient();
        let response = await client.getRequest(API_URL);

        if (response.result && response.result.length === 0) {
          /// create services settings

          this.service_setting = {
            apiserver_url: this.getAPIServerURL,
            websocket_url: this.getWSServerURL,

            vms_default_visit_access_type: ["none"],
            vms_kiosk_company_banner: null,
            vms_kiosk_company_name: null
          };

          this.setting = {
            service_setting: this.service_setting
          };

          console.log("this.setting", this.setting);
          this.createSetting(this.service_setting);
        } else {
          this.setting = response.result[0];
          console.log("this.setting", this.setting);

          this.service_setting = {
            apiserver_url: this.getAPIServerURL,
            websocket_url: this.getWSServerURL,

            vms_default_visit_access_type: ["none"],
            vms_kiosk_company_banner: null,
            vms_kiosk_company_name: null
          };

          console.log("this.service_setting", this.service_setting);

          this.service_setting = {
            ...this.service_setting,
            ...this.setting["service_setting"]
          };

          console.log("this.service_setting2", this.service_setting);
        }
      } catch (err) {
        let message = {
          message_text: err.toString(),
          message_type: "danger"
        };
        await this.$store.dispatch("session/addGlobalAlertMessage", message);
      }
    },

    /** P1 **/
    async getEpP1Services() {
      this.p1ServicesTableIsBusy = true;
      try {
        let result = await this.getMonitorServices();
        let securityResult = await this.getSecuritySettings();

        let EP_security;

        console.log("securityResult");
        console.log(securityResult);

        for (const url in securityResult) {
          console.log("securityResult[url].result");
          console.log(securityResult[url].result);

          if (securityResult[url].result.EP_P1) {
            EP_security = securityResult[url].result.EP_P1;
          }
        }

        console.log("EP_security");
        console.log(EP_security);

        let p1Services = [];
        for (const url in result) {
          for (let i = 0; i < result[url].length; i++) {
            if (result[url][i].group === "EP_P1") {
              console.log("p1Services.push(result[url][i]);");
              console.log(result[url][i]);

              this.p1_url = result[url][i].url;

              if (EP_security[result[url][i].url]) {
                p1Services.push({
                  ...result[url][i],
                  security: EP_security[result[url][i].url]
                });
              } else {
                p1Services.push({
                  ...result[url][i],
                  security: {
                    qr_key: "12345678",
                    p1_aes_key: "88888888",
                    qr_sequence:
                      "aaaaaabbbbbbbbbbaaaaaaccccccccccccaaaaaaddddddddddddaaaaeaaaaaaa",
                    qr_timezone: "Asia/Kuala_Lumpur"
                  }
                });
              }
            }
          }
        }

        this.p1ServicesTableItems = p1Services;
        this.p1ServicesTableIsBusy = false;
      } catch (err) {
        let message = {
          message_text: err.toString(),
          message_type: "danger"
        };
        await this.$store.dispatch("session/addGlobalAlertMessage", message);
        this.p1ServicesTableIsBusy = false;
      }
    },

    async getMonitorServices() {
      let API_URL = this.getAPIServerURL + "/api/monitorservice/service/";
      const client = this.$root.getAjaxFetchClient();
      let response = await client.getRequest(API_URL);
      return response.result;
    },

    async getSecuritySettings() {
      let API_URL =
        this.getAPIServerURL + "/api/monitorservice/security-settings/";
      const client = this.$root.getAjaxFetchClient();
      let response = await client.getRequest(API_URL);
      return response.result;
    },

    handleAddP1() {
      this.editP1Form = false;
      this.p1ModalTitle = "Add P1";
      this.$bvModal.show("p1_modal");
    },

    async onSubmitP1Form() {
      this.$v.p1Form.$touch();
      if (this.$v.p1Form.$anyError) {
        return;
      }

      if (this.editP1Form) {
        let $this = this;
        this.$bvModal
          .msgBoxConfirm(
            "Confirm changing your p1 setting? Pfx certificate will be auto downloaded and you will be re-install it in your P1.",
            {
              centered: true
            }
          )
          .then((value) => {
            if (value) {
              console.log("edit");
              console.log($this.p1Form.id);
              console.log($this.p1Form);
              this.editMonitorService($this.p1Form.id, $this.p1Form);
            }
          })
          .catch((err) => {
            // An error occurred
          });
      } else {
        this.addMonitorService(this.p1Form);
      }
    },

    editMonitorService(id, data) {
      let url =
        this.getAPIServerURL + "/api/monitorservice/service/" + id + "/";
      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(url, data)
        .then(() => {
          let message = {
            message_text: "Update Service Success",
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);

          this.getEpP1Services();

          if (data.url != this.p1_url) {
            this.regenerateSslCert(data.hostname);
          }
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },

    addMonitorService(data) {
      let url = this.getAPIServerURL + "/api/monitorservice/service/";
      const client = this.$root.getAjaxFetchClient();

      client
        .postRequest(url, data)
        .then(() => {
          let message = {
            message_text: "Add New Service Success",
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);

          this.getEpP1Services();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },

    resetP1Form() {
      this.p1Form = {
        url: null,
        group: "EP_P1",
        meta: {
          evim: {
            url: null,
            meta: {},
            params: {
              api_params: {
                base_url: null
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          rest: {
            url: null,
            meta: {},
            params: {
              api_params: {
                retries: 1,
                timeout: 8,
                base_url: null,
                ssl_verify: null
              }
            }
          },
          xsocket: {
            url: null,
            meta: {},
            params: {
              api_params: {
                base_url: null,
                service_id: "SC_EVA"
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          transaction_server: {
            url: null,
            meta: {},
            params: {}
          }
        },
        params: {
          api_params: {
            retries: 1,
            timeout: 8,
            base_url: null,
            ssl_verify: null
          }
        },
        active: true
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    autoFillP1Form() {
      try {
        // let array = this.p1Form.url.split("//");
        // let updateUrl = array[1];

        //let { hostname } = new URL(this.p1Form.url);

        let hostname = this.p1Form.hostname;

        let file_name = hostname.replaceAll(".", "_");

        this.p1Form = {
          ...this.p1Form,
          url: this.p1Form.protocol + this.p1Form.hostname,
          group: "EP_P1",
          meta: {
            evim: {
              url: "tcp://" + hostname + ":2606",
              meta: {},
              params: {
                api_params: {
                  base_url: "tcp://" + hostname + ":2606"
                },
                reader_timeout: 3,
                connect_timeout: 3
              }
            },
            rest: {
              url: "https://" + hostname + ":6060",
              meta: {},
              params: {
                api_params: {
                  retries: 1,
                  timeout: 8,
                  base_url: "https://" + hostname + ":6060",
                  ssl_verify: "/home/canopy/.ssh/" + file_name + ".pem"
                }
              }
            },
            xsocket: {
              url: "tcp://" + hostname + ":2604",
              meta: {},
              params: {
                api_params: {
                  base_url: "tcp://" + hostname + ":2604",
                  service_id: "SC_EVA"
                },
                reader_timeout: 3,
                connect_timeout: 3
              }
            },
            transaction_server: {
              url: "tcp://" + hostname + ":18006",
              meta: {},
              params: {}
            }
          },
          params: {
            api_params: {
              retries: 1,
              timeout: 8,
              base_url: "https://" + hostname + ":6060",
              ssl_verify: "/home/canopy/.ssh/" + file_name + ".pem"
            }
          },
          active: true
        };
      } catch (e) {
        console.log(e);
      }

      // this.$nextTick(() => {
      //   this.$v.$reset();
      // });
    },

    handleEditP1(data) {
      console.log("handleEditP1");
      console.log(data);

      this.p1Form = data;

      let urlObject;
      let hostName;
      let protocol;
      try {
        urlObject = new URL(data.url);
        hostName = urlObject.hostname;
        protocol = urlObject.protocol;
      } catch (e) {
        hostName = null;
        protocol = "http://";
      }

      this.p1Form = {
        ...this.p1Form,
        hostname: hostName,
        protocol: protocol + "//"
      };

      this.editP1Form = true;
      this.p1ModalTitle = "Edit P1";
      this.$bvModal.show("p1_modal");
    },

    confirmDeleteMonitorService(data) {
      var $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm deleting " + data.url + " ?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            $this.deleteMonitorService(data.id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    deleteMonitorService(id) {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL + "/api/monitorservice/service/" + id + "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL)
        .then(async (data) => {
          await this.getEpP1Services();

          await this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deleted service successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    /** UDF **/
    async handleEditUdf(data) {
      this.acsUrl = data.url;

      this.udf_form = {
        udF1: null,
        udF2: null,
        udF3: null,
        udF4: null,
        udF5: null,
        udF6: null,
        udF7: null,
        udF8: null,
        udF9: null,
        udF10: null
      };
      this.udfMappingId = null;
      this.editUdfForm = false;

      await this.getAllUdfMapping();

      for (let i = 0; i < this.allUdfMapping.length; i++) {
        if (this.allUdfMapping[i].url === data.url) {
          this.editUdfForm = true;

          this.udfMappingId = this.allUdfMapping[i]["id"];

          this.udf_form = {
            ...this.udf_form,
            ...this.allUdfMapping[i]["data"]
          };

          break;
        }
      }

      this.$bvModal.show("udf_modal");
    },
    async getAllUdfMapping() {
      let API_URL =
        this.getAPIServerURL + "/api/acsservice/udf-field-mapping/all/";
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);

      for (const url in res.result) {
        if (res.result[url] != null) {
          if (res.result[url]["result"].length > 0) {
            this.allUdfMapping = res.result[url]["result"];
            break;
          }
        }
      }
    },
    onSubmitUdfForm() {
      this.$v.udf_form.$touch();
      if (this.$v.p1Form.$anyError) {
        return;
      }

      let data = Object.entries(this.udf_form).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      );

      if (this.editUdfForm) {
        let finalData = {
          data: data
        };

        console.log("editUdfMapping", finalData);

        this.editUdfMapping(finalData);
      } else {
        let finalData = {
          acs_url: this.acsUrl,
          data: data
        };

        console.log("createUdfMapping", finalData);
        this.createUdfMapping(finalData);
      }
    },
    createUdfMapping(data) {
      let url = this.getAPIServerURL + "/api/acsservice/udf-field-mapping/";
      const client = this.$root.getAjaxFetchClient();

      client
        .postRequest(url, data)
        .then(() => {
          let message = {
            message_text: "Success create UDF",
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    editUdfMapping(data) {
      let url =
        this.getAPIServerURL +
        "/api/acsservice/udf-field-mapping/?udf_mapping_id=" +
        this.udfMappingId;
      const client = this.$root.getAjaxFetchClient();

      client
        .putRequest(url, data)
        .then(() => {
          let message = {
            message_text: "Success update UDF",
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    async getAcsUrl() {
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(
        this.getAPIServerURL + "/api/monitorservice/members/?group=EP_P1"
      );

      let result = res.result;
      for (const url in result) {
        if (result[url] != null) {
          if (result[url]["result"].length > 0) {
            this.acsUrl = result[url]["result"][0];
            break;
          }
        }
      }
    },

    /** API & Websocket **/
    handleEditApiWebSocket() {
      this.apiForm.apiserver_url = this.service_setting.apiserver_url;
      this.apiForm.websocket_url = this.service_setting.websocket_url;
      this.$bvModal.show("api_websocket_modal");
    },

    onSubmitApiWebsocketForm() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you confirm want to update the API & Websocket Endpoint?",
          {
            centered: true
          }
        )
        .then(async (value) => {
          if (value) {
            this.$v.apiForm.$touch();
            if (this.$v.apiForm.$anyError) {
              return;
            }

            const API_URL = this.apiForm.apiserver_url + "/license/verify/";
            const client = this.$root.getAjaxFetchClient();

            let that = this;

            client
              .postRequest(API_URL)
              .then((data) => {
                if (data.result !== null) {
                  for (const url in data.result) {
                    if (data["result"][url]["result"]) {
                      that.setting = {
                        ...that.setting,
                        service_setting: {
                          ...that.service_setting,
                          apiserver_url: that.apiForm.apiserver_url,
                          websocket_url: that.apiForm.websocket_url
                        }
                      };

                      console.log("this.setting");
                      console.log(that.setting);

                      that.editSetting(that.setting);
                    }
                  }
                }
              })
              .catch((e) => {
                console.log("verify license e");
                console.log(e);

                let message = {
                  message_text: "Please enter a licensed API Server URL.",
                  message_type: "danger"
                };

                this.$store.dispatch("session/addGlobalAlertMessage", message);
              });
          }
        });
    },
    resetApiForm() {
      this.apiForm.apiserver_url = this.getAPIServerURL;
      this.apiForm.websocket_url = this.getWSServerURL;

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    createSetting(data) {
      let url = this.getAPIServerURL + "/settings/";
      const client = this.$root.getAjaxFetchClient();

      client
        .postRequest(url, data)
        .then(() => {
          this.getAllSettings();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    editSetting(data) {
      let url = this.getAPIServerURL + "/settings/?uid=" + data.id;

      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(url, data)
        .then(async () => {
          await this.getAllSettings();

          let message = {
            message_text: "Success update settings.",
            message_type: "success"
          };

          await this.$store.dispatch("session/addGlobalAlertMessage", message);

          if (
            this.getAPIServerURL !== data.service_setting.apiserver_url ||
            this.getWSServerURL !== data.service_setting.websocket_url
          ) {
            this.updateApiserverURLAndWsserverURL(
              data.service_setting.apiserver_url,
              data.service_setting.websocket_url
            );

            location.reload();
          }
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    updateApiserverURLAndWsserverURL(apiserverURL, wsserverURL) {
      this.$store.dispatch("session/setAPIServerURL", apiserverURL);
      localStorage.setItem("apiserverURL", apiserverURL);

      this.$store.dispatch("session/setWSServerURL", wsserverURL);
      localStorage.setItem("wsserverURL", wsserverURL);
    },

    /** SMTP AND SES Messaging Setting **/
    async getAllMessaging() {
      let API_URL = this.getAPIServerURL + "/api/messageservice/settings/";
      const client = this.$root.getAjaxFetchClient();
      let response = await client.getRequest(API_URL);
      for (const url in response.result) {
        if (response.result[url] != null) {
          this.allMessaging = response.result[url];
        }
      }
    },
    handleEditMessaging() {
      this.messagingForm.email_engine = this.allMessaging.EMAIL_ENGINE;
      this.messagingForm.smtp_host = this.allMessaging.SMTP_HOST;
      this.messagingForm.smtp_port = this.allMessaging.SMTP_PORT;
      this.messagingForm.smtp_username = this.allMessaging.SMTP_USERNAME;
      this.messagingForm.smtp_password = this.allMessaging.SMTP_PASSWORD;
      this.messagingForm.aws_access_key_id =
        this.allMessaging.AWS_ACCESS_KEY_ID;
      this.messagingForm.aws_secret_access_key =
        this.allMessaging.AWS_SECRET_ACCESS_KEY;
      this.messagingForm.email_from_address =
        this.allMessaging.EMAIL_FROM_ADDRESS;
      this.messagingForm.aws_default_region =
        this.allMessaging.AWS_DEFAULT_REGION;
      this.$bvModal.show("messaging_setting_modal");
    },
    updateMessagingPayload() {
      var message_data = {
        email_engine: this.messagingForm.email_engine,
        aws_default_region: this.messagingForm.aws_default_region,
        email_from_address: this.messagingForm.email_from_address,
        aws_access_key_id: this.messagingForm.aws_access_key_id,
        aws_secret_access_key: this.messagingForm.aws_secret_access_key,
        smtp_host: this.messagingForm.smtp_host,
        smtp_port: this.messagingForm.smtp_port,
        smtp_username: this.messagingForm.smtp_username,
        smtp_password: this.messagingForm.smtp_password
      };

      return message_data;
    },
    onSubmitValidationForm() {
      this.$v.messagingForm.$touch();
      if (this.$v.messagingForm.$anyError) {
        return false;
      } else {
        return true;
      }
    },
    onSubmitForm() {
      var novalidate = this.onSubmitValidationForm();
      if (novalidate) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you confirm want to update messaging services settings?",
            {
              centered: true
            }
          )
          .then((value) => {
            if (value == true) {
              var message_update = this.updateMessagingPayload();
              this.editMessaging(message_update);
            }
          });

        this.$nextTick(() => {
          this.$bvModal.hide("messaging_setting_modal");
        });
      }
    },
    editMessaging(message_update) {
      let messaging_data = message_update;
      let url = this.getAPIServerURL + "/api/messageservice/settings/";
      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(url, messaging_data)
        .then(async () => {
          this.getAllMessaging();
          let message = {
            message_text: "Success update settings.",
            message_type: "success"
          };

          await this.$store.dispatch("session/addGlobalAlertMessage", message);
          // location.reload();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },

    regenerateSslCert(ip) {
      let $this = this;

      const API_URL = `${this.getAPIServerURL}/settings/p1-ssl-certificate/generate/?p1_ip_address=${ip}`;
      const client = this.$root.getAjaxFetchClient({ responseType: "blob" });

      console.log("API_URL");
      console.log(API_URL);

      client
        .getRequest(API_URL)
        .then((data) => {
          console.log("data");
          console.log(data);
          FileDownload(data, `${ip.replaceAll(".", "-")}.pfx`);
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
        });
    },

    handleDownloadCer(data) {
      let urlObject;
      let hostName;
      let protocol;
      try {
        urlObject = new URL(data.url);
        hostName = urlObject.hostname;
        protocol = urlObject.protocol;

        this.downloadSslCert(hostName);
      } catch (e) {
        hostName = null;
        protocol = "http://";
      }
    },

    downloadSslCert(ip) {
      let $this = this;

      const API_URL = `${this.getAPIServerURL}/settings/p1-ssl-certificate/download/?p1_ip_address=${ip}`;
      const client = this.$root.getAjaxFetchClient({ responseType: "blob" });

      console.log("API_URL");
      console.log(API_URL);

      client
        .getRequest(API_URL)
        .then((data) => {
          console.log("data");
          console.log(data);
          FileDownload(data, `${ip.replaceAll(".", "-")}.pfx`);
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
        });
    }

    /** kiosk **/
    /* showImage: function (img) {
      return img;
    },

    onFileChange: function (item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(item, files[0]);
    },
    createImage: function (item, file) {
      let $this = this;
      //let image = new Image();
      let reader = new FileReader();

      reader.onload = (e) => {
        item.company_banner = e.target.result;
        $this.kioskForm.company_banner = item.company_banner;
        //$this.showUploadPhoto = true;
      };
      reader.readAsDataURL(file);
    },

    uploadPhoto: function () {
      let $this = this;
      $this.kioskForm.company_banner = null;

      document.getElementById("kioskForm.company_banner").click();
    },

    reuploadPhoto: function () {
      let $this = this;
      $this.kioskForm.company_banner = null;
    },
    cancelUploadPhoto: function () {
      let $this = this;
      $this.kioskForm.company_banner = null;
    },

    handleEditKiosk() {
      this.kioskForm.company_banner =
        this.service_setting.vms_kiosk_company_banner;
      this.kioskForm.company_name = this.service_setting.vms_kiosk_company_name;
      this.$bvModal.show("kiosk_modal");
    },

    onSubmitKioskForm() {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you confirm want to update the Kiosk settings?", {
          centered: true
        })
        .then(async (value) => {
          if (value) {
            let publicData;
            if (
              Object.prototype.hasOwnProperty.call(
                that.service_setting,
                "public"
              )
            ) {
              publicData = {
                ...that.service_setting.public,
                vms_kiosk_company_name: that.kioskForm.company_name,
                vms_kiosk_company_banner: that.kioskForm.company_banner
              };
            } else {
              publicData = {
                vms_kiosk_company_name: that.kioskForm.company_name,
                vms_kiosk_company_banner: that.kioskForm.company_banner
              };
            }

            that.setting = {
              ...that.setting,
              service_setting: {
                ...that.service_setting,
                public: publicData,
                vms_kiosk_company_name: that.kioskForm.company_name,
                vms_kiosk_company_banner: that.kioskForm.company_banner
              }
            };

            that.editSetting(that.setting);
          }
        });
    },
    resetKioskForm() {
      this.kioskForm.company_name = this.default_company_name;
    }, */

    /** smart vms **/
    /* handleEditSmartVms() {
      this.smartVmsForm.default_visit_access_type =
        this.service_setting.vms_default_visit_access_type;
      this.$bvModal.show("smart_vms_modal");
    },

    onSubmitSmartVmsForm() {
      let that = this;

      this.$bvModal
        .msgBoxConfirm(
          "Are you confirm want to update the Smart Vms settings?",
          {
            centered: true
          }
        )
        .then(async (value) => {
          if (value) {
            that.setting = {
              ...that.setting,
              service_setting: {
                ...that.service_setting,
                vms_default_visit_access_type:
                  that.smartVmsForm.default_visit_access_type
              }
            };

            console.log("this.setting");
            console.log(that.setting);

            this.editSetting(that.setting);
          }
        });
    },
    resetSmartVmsForm() {
      this.smartVmsForm.default_visit_access_type =
        this.default_visit_access_type;
    } */
  }
};
</script>

<style lang="scss"></style>

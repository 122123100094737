<template>
  <div class="accordion" role="tablist">
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div
            v-b-toggle.smart-canopy-setting
            class="d-flex justify-content-between"
          >
            <div class="h6">
              <strong> Smart Canopy Setting </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="smart-canopy-setting" role="tabpanel">
          <b-card-body>
            <div class="my-4">
              <div class="mt-3">
                <strong> Username: </strong>
                {{ this.form.username }}
              </div>
              <div class="d-flex">
                <strong> Password: </strong>
                {{ this.form.password }}
              </div>
            </div>
            <b-button
              class="mt-3"
              variant="primary"
              size="sm"
              @click="handleEditSmartCanopySetting"
              >Update
            </b-button>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Please wait for 15 seconds...</p>
        </div>
      </template>
    </b-overlay>

    <b-modal
      title="API User Credential"
      id="smartcanopy_setting_modal"
      centered
      hide-footer
    >
      <b-form @submit.prevent="onSubmitForm">
        <b-form-group label="Username" label-for="scform.username">
          <b-form-input
            id="form.username"
            name="form.username"
            v-model="$v.formUpdate.username_update.$model"
            :state="validateState($v.formUpdate.username_update)"
            aria-describedby="form.username.feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="form.username.feedback">
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Password" label-for="scform.password">
          <b-form-input
            id="form.password"
            name="form.password"
            v-model="$v.formUpdate.password_update.$model"
            :state="validateState($v.formUpdate.password_update)"
            aria-describedby="form.password.feedback"
            type="password"
          ></b-form-input>
          <b-form-invalid-feedback id="form.password.feedback">
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  url,
  helpers,
  and
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        username: null,
        password: null
      },
      formUpdate: {
        username_update: null,
        password_update: null
      },

      show: false
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  mounted: function () {
    this.getSmartCanopySettingData();
  },
  validations: {
    formUpdate: {
      username_update: { required },
      password_update: { required }
    }
  },
  methods: {
    getSmartCanopySettingData() {
      var $this = this;
      var API_URL = $this.getAPIServerURL + "/settings/workflow-settings/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          this.form.username = data.username;
          this.form.password = data.password;
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    handleEditSmartCanopySetting() {
      this.formUpdate.username_update = this.form.username;
      this.formUpdate.password_update = this.form.password;
      this.$bvModal.show("smartcanopy_setting_modal");
    },
    validateState(field) {
      const { $dirty, $error } = field;
      return $dirty ? !$error : null;
    },
    onSubmitValidationForm() {
      this.$v.formUpdate.$touch();
      if (
        this.$v.formUpdate.username_update.$anyError ||
        this.$v.formUpdate.password_update.$anyError
      ) {
        return false;
      } else {
        return true;
      }
    },
    onSubmitForm() {
      var novalidate = this.onSubmitValidationForm();
      if (novalidate) {
        this.$bvModal
          .msgBoxConfirm("Confirm update api user credential settings?", {
            centered: true
          })
          .then((value) => {
            if (value == true) {
              console.log("this.formUpdate", this.formUpdate);
              this.editApiUserCredential();
            }
          });

        this.$nextTick(() => {
          this.$bvModal.hide("smartcanopy_setting_modal");
        });
      } else {
        this.$bvModal.msgBoxOk("Form not completed!", {
          centered: true
        });
        return false;
      }
    },

    editApiUserCredential() {
      this.show = true;
      let update = {
        username: this.formUpdate.username_update,
        password: this.formUpdate.password_update
      };
      let API_URL = this.getAPIServerURL + "/settings/workflow-settings/";
      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, update)
        .then(async (data) => {
          console.log("data", data);
          let message = null;
          if (data == null) {
            setTimeout(function () {
              message = {
                message_text: "Success update settings. ",
                message_type: "success"
              };
              this.show = false;
              location.reload();
            }, 15000);

            await this.$store.dispatch(
              "session/addGlobalAlertMessage",
              message
            );
          } else {
            message = {
              message_text: "Update settings is not success.",
              message_type: "danger"
            };

            await this.$store.dispatch(
              "session/addGlobalAlertMessage",
              message
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
          /* let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message); */
        });
    }
  }
};
</script>

<style lang="scss">
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>

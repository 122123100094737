<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.email_setting class="d-flex justify-content-between">
            <div class="h6">
              <strong> Email Setting </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="email_setting" role="tabpanel">
          <b-card-body>
            <div v-if="allMessaging.EMAIL_ENGINE == 'SMTP'">
              <div class="my-4">
                <div class="mt-3">
                  EMAIL FROM ADDRESS:
                  <strong>
                    {{ allMessaging.EMAIL_FROM_ADDRESS }}
                  </strong>
                </div>
                <div>
                  EMAIL ENGINE:
                  <strong> {{ allMessaging.EMAIL_ENGINE }} </strong>
                </div>
                <div>
                  SMTP HOST:
                  <strong> {{ allMessaging.SMTP_HOST }}</strong>
                </div>
                <div>
                  SMTP PORT:
                  <strong> {{ allMessaging.SMTP_PORT }} </strong>
                </div>
                <div>
                  SMTP USERNAME:
                  <strong> {{ allMessaging.SMTP_USERNAME }} </strong>
                </div>
                <div>
                  SMTP PASSWORD:
                  <strong> ********** </strong>
                  <!--                      <strong> {{ allMessaging.SMTP_PASSWORD }} </strong>-->
                </div>

                <!--                  <div class="mt-3">AWS SNS Notification Engine (SMS)</div>-->
                <!--                  <div>-->
                <!--                    NOTIFICATION_ENGINE:-->
                <!--                    <strong> SNS </strong>-->
                <!--                  </div>-->
                <!--                  <div>-->
                <!--                    AWS SNS ACCESS KEY ID:-->
                <!--                    <strong> KH78GDIWDE837EDJW3 </strong>-->
                <!--                  </div>-->
                <!--                  <div>-->
                <!--                    AWS SNS SECRET ACCESS KEY:-->
                <!--                    <strong> h5j84d3k9s232d54dew9 </strong>-->
                <!--                  </div>-->
                <!--                  <div class="mt-3">FCM Engine</div>-->
                <!--                  <div>-->
                <!--                    FCM_ENGINE:-->
                <!--                    <strong> PYFCM </strong>-->
                <!--                  </div>-->
                <!--                  <div>-->
                <!--                    FCM SENDER ID:-->
                <!--                    <strong> 38217649218649 </strong>-->
                <!--                  </div>-->
                <!--                  <div>-->
                <!--                    FCM API KEY:-->
                <!--                    <strong> AQH282W1BSDJWQD8IWDHUW3 </strong>-->
                <!--                  </div>-->
                <b-button
                  class="mt-3"
                  variant="primary"
                  size="sm"
                  @click="handleEditMessaging"
                  >Update
                </b-button>
              </div>
            </div>
            <div v-if="allMessaging.EMAIL_ENGINE == 'SES'">
              <div class="my-4">
                <div class="mt-3">
                  EMAIL FROM ADDRESS:
                  <strong>
                    {{ allMessaging.EMAIL_FROM_ADDRESS }}
                  </strong>
                </div>
                <div>
                  EMAIL ENGINE:<strong> {{ allMessaging.EMAIL_ENGINE }}</strong>
                </div>
                <div>
                  AWS ACCESS KEY ID:
                  <strong>{{ allMessaging.AWS_ACCESS_KEY_ID }} </strong>
                </div>
                <div>
                  AWS SECRET ACCESS KEY:
                  <!--                      <strong> {{ allMessaging.AWS_SECRET_ACCESS_KEY }}</strong>-->
                  <strong> ********** </strong>
                </div>
                <div>
                  AWS DEFAULT REGION:
                  <strong> {{ allMessaging.AWS_DEFAULT_REGION }}</strong>
                </div>

                <!--                  <div class="mt-3">AWS SNS Notification Engine (SMS)</div>-->
                <!--                  <div>-->
                <!--                    NOTIFICATION_ENGINE:-->
                <!--                    <strong> SNS </strong>-->
                <!--                  </div>-->
                <!--                  <div>-->
                <!--                    AWS SNS ACCESS KEY ID:-->
                <!--                    <strong> KH78GDIWDE837EDJW3 </strong>-->
                <!--                  </div>-->
                <!--                  <div>-->
                <!--                    AWS SNS SECRET ACCESS KEY:-->
                <!--                    <strong> h5j84d3k9s232d54dew9 </strong>-->
                <!--                  </div>-->
                <!--                  <div class="mt-3">FCM Engine</div>-->
                <!--                  <div>-->
                <!--                    FCM_ENGINE:-->
                <!--                    <strong> PYFCM </strong>-->
                <!--                  </div>-->
                <!--                  <div>-->
                <!--                    FCM SENDER ID:-->
                <!--                    <strong> 38217649218649 </strong>-->
                <!--                  </div>-->
                <!--                  <div>-->
                <!--                    FCM API KEY:-->
                <!--                    <strong> AQH282W1BSDJWQD8IWDHUW3 </strong>-->
                <!--                  </div>-->
                <b-button
                  class="mt-3"
                  variant="primary"
                  size="sm"
                  @click="handleEditMessaging"
                  >Update
                </b-button>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <!-- SMTP and SES Setting modal -->
    <b-modal
      title="Messaging Services"
      id="messaging_setting_modal"
      centered
      hide-footer
    >
      <b-form @submit.prevent="onSubmitForm">
        <b-form-group
          label="EMAIL FROM ADDRESS"
          label-for="sesForm.email_from_address"
        >
          <b-form-input
            id="messagingForm.email_from_address"
            name="messagingForm.email_from_address"
            v-model="$v.messagingForm.email_from_address.$model"
            :state="validateState($v.messagingForm.email_from_address)"
            aria-describedby="messagingForm.email_from_address.feedback"
            required
          ></b-form-input>
          <b-form-invalid-feedback
            id="messagingForm.email_from_address.feedback"
          >
            This field is required.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-email-type"
          label="EMAIL ENGINE"
          label-for="input-email-type"
        >
          <b-form-select
            id="input-email-type"
            v-model="messagingForm.email_engine"
            :options="email_engine"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          v-if="messagingForm.email_engine == 'SMTP'"
          id="input-group-smtp"
          label-for="input-smtp"
        >
          <b-form-group label="SMTP HOST" label-for="messagingForm.smtp_host">
            <b-form-input
              id="messagingForm.smtp_host"
              name="messagingForm.smtp_host"
              v-model="$v.messagingForm.smtp_host.$model"
              :state="validateState($v.messagingForm.smtp_host)"
              aria-describedby="messagingForm.smtp_host.feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="messagingForm.smtp_host.feedback">
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="SMTP PORT" label-for="messagingForm.smtp_port">
            <b-form-input
              id="messagingForm.smtp_port"
              name="messagingForm.smtp_port"
              v-model="$v.messagingForm.smtp_port.$model"
              :state="validateState($v.messagingForm.smtp_port)"
              aria-describedby="messagingForm.smtp_port.feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="messagingForm.smtp_port.feedback">
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label="SMTP USERNAME"
            label-for="messagingForm.smtp_username"
          >
            <b-form-input
              id="messagingForm.smtp_username"
              name="messagingForm.smtp_username"
              v-model="$v.messagingForm.smtp_username.$model"
              :state="validateState($v.messagingForm.smtp_username)"
              aria-describedby="messagingForm.smtp_username.feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="messagingForm.smtp_username.feedback">
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label="SMTP PASSWORD"
            label-for="messagingForm.smtp_password"
          >
            <b-form-input
              type="password"
              id="messagingForm.smtp_password"
              name="messagingForm.smtp_password"
              v-model="$v.messagingForm.smtp_password.$model"
              :state="validateState($v.messagingForm.smtp_password)"
              aria-describedby="messagingForm.smtp_password.feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback id="messagingForm.smtp_password.feedback">
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-group>
        <b-form-group
          v-if="messagingForm.email_engine == 'SES'"
          id="input-group-ses"
          label-for="input-ses"
        >
          <b-form-group
            label="AWS ACCESS KEY ID"
            label-for="messagingForm.aws_access_key_id"
          >
            <b-form-input
              id="messagingForm.aws_access_key_id"
              name="messagingForm.aws_access_key_id"
              v-model="$v.messagingForm.aws_access_key_id.$model"
              :state="validateState($v.messagingForm.aws_access_key_id)"
              aria-describedby="messagingForm.aws_access_key_id.feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback
              id="messagingForm.aws_access_key_id.feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label="AWS SECRET ACCESS KEY"
            label-for="messagingForm.aws_secret_access_key"
          >
            <b-form-input
              type="password"
              id="messagingForm.aws_secret_access_key"
              name="messagingForm.aws_secret_access_key"
              v-model="$v.messagingForm.aws_secret_access_key.$model"
              :state="validateState($v.messagingForm.aws_secret_access_key)"
              aria-describedby="messagingForm.aws_secret_access_key.feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback
              id="messagingForm.aws_secret_access_key.feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label="AWS DEFAULT REGION"
            label-for="messagingForm.aws_default_region"
          >
            <b-form-input
              id="messagingForm.aws_default_region"
              name="messagingForm.aws_default_region"
              v-model="$v.messagingForm.aws_default_region.$model"
              :state="validateState($v.messagingForm.aws_default_region)"
              aria-describedby="messagingForm.aws_default_region.feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback
              id="messagingForm.aws_default_region.feedback"
            >
              This field is required.
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form-group>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  url,
  helpers,
  and
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import store from "../../store";

var validUrl = require("valid-url");
var isUrl = require("is-url");
const alpha = helpers.regex("alpha", /^\S*$/);

const urlValidation2 = (value) => {
  try {
    if (value) {
      if (validUrl.isUri(value) && isUrl(value)) {
        try {
          let { hostname } = new URL(value);

          if (hostname) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }

        // let array = value.split("//");
        //
        // if(array[1].includes(".")){
        //
        //   let array2 = array[1].split(".");
        //
        //   console.log("array2", array2)
        //   if(array2.length === 4){
        //     return true;
        //   }else{
        //     return false;
        //   }
        //
        // }else{
        //   return true;
        // }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export default {
  mixins: [validationMixin],
  data() {
    return {
      pageTitle: "Service Settings",

      /** service_setting **/
      setting: null,
      service_setting: {
        apiserver_url: null,
        websocket_url: null,

        vms_default_visit_access_type: null,
        vms_kiosk_company_banner: null,
        vms_kiosk_company_name: null
      },

      vms_default_visit_access_type_options: [
        { text: "Card Access", value: "card" },
        { text: "Dynamic QR Access", value: "dynamic_qr" },
        { text: "Static QR Access", value: "static_qr" },
        { text: "Face Access", value: "face" }
      ],

      /** API & Websocket **/
      apiForm: {
        apiserver_url: null,
        websocket_url: null
      },

      /** Kiosk **/
      /* kioskForm: {
        company_banner: null,
        company_name: null
      }, */

      /** smart vms**/
      /* smartVmsForm: {
        default_visit_access_type: null
      }, */

      /** UDF **/
      editUdfForm: false,
      allUdfMapping: [],
      udf_form: {
        udF1: null,
        udF2: null,
        udF3: null,
        udF4: null,
        udF5: null,
        udF6: null,
        udF7: null,
        udF8: null,
        udF9: null,
        udF10: null
      },
      udfMappingId: null,
      acsUrl: null,

      /** P1 **/
      p1ServicesTableItems: [],
      p1ServicesTableFields: [
        { key: "url", label: "URL" },
        { key: "actions", label: "Actions" }
      ],
      p1ServicesTableIsBusy: false,
      p1ModalTitle: null,
      editP1Form: false,
      p1Form: {
        url: null,
        group: "EP_P1",
        meta: {
          evim: {
            url: null,
            meta: {},
            params: {
              api_params: {
                base_url: null
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          rest: {
            url: null,
            meta: {},
            params: {
              api_params: {
                retries: 1,
                timeout: 8,
                base_url: null,
                ssl_verify: null
              }
            }
          },
          xsocket: {
            url: null,
            meta: {},
            params: {
              api_params: {
                base_url: null,
                service_id: "SC_EVA"
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          transaction_server: {
            url: null,
            meta: {},
            params: {}
          }
        },
        params: {
          api_params: {
            retries: 1,
            timeout: 8,
            base_url: null,
            ssl_verify: null
          }
        },
        active: true
      },

      /** messaging **/
      allMessaging: {},
      messagingForm: {
        smtp_host: null,
        smtp_port: null,
        smtp_username: null,
        smtp_password: null,
        email_engine: null,
        aws_default_region: null,
        email_from_address: null,
        aws_access_key_id: null,
        aws_secret_access_key: null
      },
      EMAIL_ENGINE: null,
      SMTP_HOST: null,
      SMTP_PORT: null,
      SMTP_USERNAME: null,
      SMTP_PASSWORD: null,
      AWS_DEFAULT_REGION: null,
      EMAIL_FROM_ADDRESS: null,
      AWS_ACCESS_KEY_ID: null,
      AWS_SECRET_ACCESS_KEY: null,
      email_engine: [
        { value: "SMTP", text: "SMTP" },
        { value: "SES", text: "SES" }
      ]
    };
  },
  mounted() {
    this.getAllSettings();
    this.getEpP1Services();
    this.getAllMessaging();
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL",
      getWSServerURL: "session/getWSServerURL"
    })
  },
  validations: {
    p1Form: {
      url: { urlValidation2 },
      group: { required },
      meta: {
        evim: {
          url: { required },
          meta: {},
          params: {
            api_params: {
              base_url: { required }
            },
            reader_timeout: { required },
            connect_timeout: { required }
          }
        },
        rest: {
          url: { required },
          meta: {},
          params: {
            api_params: {
              retries: { required },
              timeout: { required },
              base_url: { required },
              ssl_verify: { required }
            }
          }
        },
        xsocket: {
          url: { required },
          meta: {},
          params: {
            api_params: {
              base_url: { required },
              service_id: { required }
            },
            reader_timeout: { required },
            connect_timeout: { required }
          }
        },
        transaction_server: {
          url: { required },
          meta: {},
          params: {}
        }
      },
      params: {
        api_params: {
          retries: { required },
          timeout: { required },
          base_url: { required },
          ssl_verify: { required }
        }
      },
      active: true
    },

    udf_form: {
      udF1: {
        alpha
      },
      udF2: {
        alpha
      },
      udF3: {
        alpha
      },
      udF4: {
        alpha
      },
      udF5: {
        alpha
      },
      udF6: {
        alpha
      },
      udF7: {
        alpha
      },
      udF8: {
        alpha
      },
      udF9: {
        alpha
      },
      udF10: {
        alpha
      }
    },
    apiserver_url: null,
    websocket_url: null,
    apiForm: {
      apiserver_url: { urlValidation2 },
      websocket_url: { urlValidation2 }
    },
    /* kioskForm: {
      company_banner: { required },
      company_name: { required }
    }, */
    messagingForm: {
      smtp_host: { required },
      smtp_port: { required },
      smtp_username: { required },
      smtp_password: { required },
      email_engine: { required },
      aws_default_region: { required },
      email_from_address: { required },
      aws_access_key_id: { required },
      aws_secret_access_key: { required }
    },
    EMAIL_ENGINE: null,
    SMTP_HOST: null,
    SMTP_PORT: null,
    SMTP_USERNAME: null,
    SMTP_PASSWORD: null,
    AWS_DEFAULT_REGION: null,
    EMAIL_FROM_ADDRESS: null,
    AWS_ACCESS_KEY_ID: null,
    AWS_SECRET_ACCESS_KEY: null
  },
  methods: {
    /** form validation **/
    validateState(field) {
      const { $dirty, $error } = field;
      return $dirty ? !$error : null;
    },
    enableAutoFillButton(field) {
      const { $error } = field;
      return !$error;
    },

    /** Settings **/
    async getAllSettings() {
      try {
        let API_URL = this.getAPIServerURL + "/settings/all/";
        const client = this.$root.getAjaxFetchClient();
        let response = await client.getRequest(API_URL);

        if (response.result && response.result.length === 0) {
          /// create services settings

          this.service_setting = {
            apiserver_url: this.getAPIServerURL,
            websocket_url: this.getWSServerURL,

            vms_default_visit_access_type: ["none"],
            vms_kiosk_company_banner: null,
            vms_kiosk_company_name: null
          };

          this.setting = {
            service_setting: this.service_setting
          };

          this.createSetting(this.service_setting);
        } else {
          this.setting = response.result[0];

          this.service_setting = {
            apiserver_url: this.getAPIServerURL,
            websocket_url: this.getWSServerURL,

            vms_default_visit_access_type: ["none"],
            vms_kiosk_company_banner: null,
            vms_kiosk_company_name: null
          };

          this.service_setting = {
            ...this.service_setting,
            ...this.setting["service_setting"]
          };
        }
      } catch (err) {
        let message = {
          message_text: err.toString(),
          message_type: "danger"
        };
        await this.$store.dispatch("session/addGlobalAlertMessage", message);
      }
    },

    /** P1 **/
    async getEpP1Services() {
      this.p1ServicesTableIsBusy = true;
      try {
        let result = await this.getMonitorServices();
        let p1Services = [];
        for (const url in result) {
          for (let i = 0; i < result[url].length; i++) {
            if (result[url][i].group === "EP_P1") {
              p1Services.push(result[url][i]);
            }
          }
        }

        this.p1ServicesTableItems = p1Services;
        this.p1ServicesTableIsBusy = false;
      } catch (err) {
        let message = {
          message_text: err.toString(),
          message_type: "danger"
        };
        await this.$store.dispatch("session/addGlobalAlertMessage", message);
        this.p1ServicesTableIsBusy = false;
      }
    },

    async getMonitorServices() {
      let API_URL = this.getAPIServerURL + "/api/monitorservice/service/";
      const client = this.$root.getAjaxFetchClient();
      let response = await client.getRequest(API_URL);
      return response.result;
    },

    handleAddP1() {
      this.editP1Form = false;
      this.p1ModalTitle = "Add P1";
      this.$bvModal.show("p1_modal");
    },

    async onSubmitP1Form() {
      this.$v.p1Form.$touch();
      if (this.$v.p1Form.$anyError) {
        return;
      }

      if (this.editP1Form) {
        this.editMonitorService(this.p1Form.id, this.p1Form);
      } else {
        this.addMonitorService(this.p1Form);
      }
    },

    editMonitorService(id, data) {
      let url =
        this.getAPIServerURL + "/api/monitorservice/service/" + id + "/";
      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(url, data)
        .then(() => {
          let message = {
            message_text: "Update Service Success",
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);

          this.getEpP1Services();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },

    addMonitorService(data) {
      let url = this.getAPIServerURL + "/api/monitorservice/service/";
      const client = this.$root.getAjaxFetchClient();

      client
        .postRequest(url, data)
        .then(() => {
          let message = {
            message_text: "Add New Service Success",
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);

          this.getEpP1Services();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },

    resetP1Form() {
      this.p1Form = {
        url: null,
        group: "EP_P1",
        meta: {
          evim: {
            url: null,
            meta: {},
            params: {
              api_params: {
                base_url: null
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          rest: {
            url: null,
            meta: {},
            params: {
              api_params: {
                retries: 1,
                timeout: 8,
                base_url: null,
                ssl_verify: null
              }
            }
          },
          xsocket: {
            url: null,
            meta: {},
            params: {
              api_params: {
                base_url: null,
                service_id: "SC_EVA"
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          transaction_server: {
            url: null,
            meta: {},
            params: {}
          }
        },
        params: {
          api_params: {
            retries: 1,
            timeout: 8,
            base_url: null,
            ssl_verify: null
          }
        },
        active: true
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    autoFillP1Form() {
      // let array = this.p1Form.url.split("//");
      // let updateUrl = array[1];

      let { hostname } = new URL(this.p1Form.url);

      let file_name = hostname.replaceAll(".", "_");

      this.p1Form = {
        ...this.p1Form,

        group: "EP_P1",
        meta: {
          evim: {
            url: "tcp://" + hostname + ":2606",
            meta: {},
            params: {
              api_params: {
                base_url: "tcp://" + hostname + ":2606"
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          rest: {
            url: "https://" + hostname + ":6060",
            meta: {},
            params: {
              api_params: {
                retries: 1,
                timeout: 8,
                base_url: "https://" + hostname + ":6060",
                ssl_verify: "/home/canopy/.ssh/" + file_name + ".pem"
              }
            }
          },
          xsocket: {
            url: "tcp://" + hostname + ":2604",
            meta: {},
            params: {
              api_params: {
                base_url: "tcp://" + hostname + ":2604",
                service_id: "SC_EVA"
              },
              reader_timeout: 3,
              connect_timeout: 3
            }
          },
          transaction_server: {
            url: "tcp://" + hostname + ":18006",
            meta: {},
            params: {}
          }
        },
        params: {
          api_params: {
            retries: 1,
            timeout: 8,
            base_url: "https://" + hostname + ":6060",
            ssl_verify: "/home/canopy/.ssh/" + file_name + ".pem"
          }
        },
        active: true
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    handleEditP1(data) {
      this.editP1Form = true;
      this.p1ModalTitle = "Edit P1";
      this.$bvModal.show("p1_modal");
      this.p1Form = data;
    },

    confirmDeleteMonitorService(data) {
      var $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm deleting " + data.url + " ?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            $this.deleteMonitorService(data.id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    deleteMonitorService(id) {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL + "/api/monitorservice/service/" + id + "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL)
        .then(async (data) => {
          await this.getEpP1Services();

          await this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deleted service successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    /** UDF **/
    async handleEditUdf(data) {
      this.acsUrl = data.url;

      this.udf_form = {
        udF1: null,
        udF2: null,
        udF3: null,
        udF4: null,
        udF5: null,
        udF6: null,
        udF7: null,
        udF8: null,
        udF9: null,
        udF10: null
      };
      this.udfMappingId = null;
      this.editUdfForm = false;

      await this.getAllUdfMapping();

      for (let i = 0; i < this.allUdfMapping.length; i++) {
        if (this.allUdfMapping[i].url === data.url) {
          this.editUdfForm = true;

          this.udfMappingId = this.allUdfMapping[i]["id"];

          this.udf_form = {
            ...this.udf_form,
            ...this.allUdfMapping[i]["data"]
          };

          break;
        }
      }

      this.$bvModal.show("udf_modal");
    },
    async getAllUdfMapping() {
      let API_URL =
        this.getAPIServerURL + "/api/acsservice/udf-field-mapping/all/";
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);

      for (const url in res.result) {
        if (res.result[url] != null) {
          if (res.result[url]["result"].length > 0) {
            this.allUdfMapping = res.result[url]["result"];
            break;
          }
        }
      }
    },
    onSubmitUdfForm() {
      this.$v.udf_form.$touch();
      if (this.$v.p1Form.$anyError) {
        return;
      }

      let data = Object.entries(this.udf_form).reduce(
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      );

      if (this.editUdfForm) {
        let finalData = {
          data: data
        };

        this.editUdfMapping(finalData);
      } else {
        let finalData = {
          acs_url: this.acsUrl,
          data: data
        };
        this.createUdfMapping(finalData);
      }
    },
    createUdfMapping(data) {
      let url = this.getAPIServerURL + "/api/acsservice/udf-field-mapping/";
      const client = this.$root.getAjaxFetchClient();

      client
        .postRequest(url, data)
        .then(() => {
          let message = {
            message_text: "Success create UDF",
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    editUdfMapping(data) {
      let url =
        this.getAPIServerURL +
        "/api/acsservice/udf-field-mapping/?udf_mapping_id=" +
        this.udfMappingId;
      const client = this.$root.getAjaxFetchClient();

      client
        .putRequest(url, data)
        .then(() => {
          let message = {
            message_text: "Success update UDF",
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    async getAcsUrl() {
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(
        this.getAPIServerURL + "/api/monitorservice/members/?group=EP_P1"
      );

      let result = res.result;
      for (const url in result) {
        if (result[url] != null) {
          if (result[url]["result"].length > 0) {
            this.acsUrl = result[url]["result"][0];
            break;
          }
        }
      }
    },

    /** API & Websocket **/
    handleEditApiWebSocket() {
      this.apiForm.apiserver_url = this.service_setting.apiserver_url;
      this.apiForm.websocket_url = this.service_setting.websocket_url;
      this.$bvModal.show("api_websocket_modal");
    },

    onSubmitApiWebsocketForm() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you confirm want to update the API & Websocket Endpoint?",
          {
            centered: true
          }
        )
        .then(async (value) => {
          if (value) {
            this.$v.apiForm.$touch();
            if (this.$v.apiForm.$anyError) {
              return;
            }

            const API_URL = this.apiForm.apiserver_url + "/license/verify/";
            const client = this.$root.getAjaxFetchClient();

            let that = this;

            client
              .postRequest(API_URL)
              .then((data) => {
                if (data.result !== null) {
                  for (const url in data.result) {
                    if (data["result"][url]["result"]) {
                      that.setting = {
                        ...that.setting,
                        service_setting: {
                          ...that.service_setting,
                          apiserver_url: that.apiForm.apiserver_url,
                          websocket_url: that.apiForm.websocket_url
                        }
                      };

                      that.editSetting(that.setting);
                    }
                  }
                }
              })
              .catch((e) => {
                let message = {
                  message_text: "Please enter a licensed API Server URL.",
                  message_type: "danger"
                };

                this.$store.dispatch("session/addGlobalAlertMessage", message);
              });
          }
        });
    },
    resetApiForm() {
      this.apiForm.apiserver_url = this.getAPIServerURL;
      this.apiForm.websocket_url = this.getWSServerURL;

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    createSetting(data) {
      let url = this.getAPIServerURL + "/settings/";
      const client = this.$root.getAjaxFetchClient();

      client
        .postRequest(url, data)
        .then(() => {
          this.getAllSettings();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    editSetting(data) {
      let url = this.getAPIServerURL + "/settings/?uid=" + data.id;

      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(url, data)
        .then(async () => {
          await this.getAllSettings();

          let message = {
            message_text: "Success update settings.",
            message_type: "success"
          };

          await this.$store.dispatch("session/addGlobalAlertMessage", message);

          if (
            this.getAPIServerURL !== data.service_setting.apiserver_url ||
            this.getWSServerURL !== data.service_setting.websocket_url
          ) {
            this.updateApiserverURLAndWsserverURL(
              data.service_setting.apiserver_url,
              data.service_setting.websocket_url
            );

            location.reload();
          }
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    updateApiserverURLAndWsserverURL(apiserverURL, wsserverURL) {
      this.$store.dispatch("session/setAPIServerURL", apiserverURL);
      localStorage.setItem("apiserverURL", apiserverURL);

      this.$store.dispatch("session/setWSServerURL", wsserverURL);
      localStorage.setItem("wsserverURL", wsserverURL);
    },

    /** SMTP AND SES Messaging Setting **/
    async getAllMessaging() {
      let API_URL = this.getAPIServerURL + "/api/messageservice/settings/";
      const client = this.$root.getAjaxFetchClient();
      let response = await client.getRequest(API_URL);
      for (const url in response.result) {
        if (response.result[url] != null) {
          this.allMessaging = response.result[url];
          console.log("this.allMessaging", this.allMessaging);
        }
      }
    },
    handleEditMessaging() {
      this.messagingForm.email_engine = this.allMessaging.EMAIL_ENGINE;
      this.messagingForm.email_from_address =
        this.allMessaging.EMAIL_FROM_ADDRESS;
      this.messagingForm.smtp_host = this.allMessaging.SMTP_HOST;
      this.messagingForm.smtp_port = this.allMessaging.SMTP_PORT;
      this.messagingForm.smtp_username = this.allMessaging.SMTP_USERNAME;
      this.messagingForm.smtp_password = "*".repeat(12);
      this.messagingForm.aws_access_key_id = "*".repeat(12);
      this.messagingForm.aws_secret_access_key = "*".repeat(12);
      this.messagingForm.aws_default_region =
        this.allMessaging.AWS_DEFAULT_REGION;
      this.$bvModal.show("messaging_setting_modal");
    },
    updateMessagingPayload() {
      var message_data = {
        email_engine: this.messagingForm.email_engine,
        aws_default_region: this.messagingForm.aws_default_region,
        email_from_address: this.messagingForm.email_from_address,
        smtp_host: this.messagingForm.smtp_host,
        smtp_port: this.messagingForm.smtp_port,
        smtp_username: this.messagingForm.smtp_username
      };

      if (this.messagingForm.smtp_password != "*".repeat(12))
        message_data["smtp_password"] = this.messagingForm.smtp_password;

      if (this.messagingForm.aws_secret_access_key != "*".repeat(12))
        message_data["aws_secret_access_key"] =
          this.messagingForm.aws_secret_access_key;

      if (this.messagingForm.aws_access_key_id != "*".repeat(12))
        message_data["aws_access_key_id"] =
          this.messagingForm.aws_access_key_id;

      return message_data;
    },
    onSubmitValidationForm() {
      this.$v.messagingForm.$touch();
      if (this.$v.messagingForm.email_engine.$model === "SES") {
        if (
          this.$v.messagingForm.aws_access_key_id.$anyError ||
          this.$v.messagingForm.aws_secret_access_key.$anyError ||
          this.$v.messagingForm.aws_default_region.$anyError
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          this.$v.messagingForm.smtp_host.$anyError ||
          this.$v.messagingForm.smtp_port.$anyError ||
          this.$v.messagingForm.smtp_username.$anyError ||
          this.$v.messagingForm.smtp_password.$anyError
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    onSubmitForm() {
      var novalidate = this.onSubmitValidationForm();
      if (novalidate) {
        this.$bvModal
          .msgBoxConfirm("Confirm update email settings?", {
            centered: true
          })
          .then((value) => {
            if (value == true) {
              var message_update = this.updateMessagingPayload();
              this.editMessaging(message_update);
            }
          });

        this.$nextTick(() => {
          this.$bvModal.hide("messaging_setting_modal");
        });
      }
    },
    editMessaging(message_update) {
      let messaging_data = message_update;
      let url = this.getAPIServerURL + "/api/messageservice/settings/";
      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(url, messaging_data)
        .then(async () => {
          this.getAllMessaging();
          let message = {
            message_text: "Success update settings.",
            message_type: "success"
          };

          await this.$store.dispatch("session/addGlobalAlertMessage", message);
          // location.reload();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    }
  }
};
</script>

<style lang="scss"></style>

<template>
  <div>
    <div class="accordion" role="tablist">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.ip_address class="d-flex justify-content-between">
            <div class="h6">
              <strong> IP Address </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="ip_address" role="tabpanel">
          <b-card-body>
            <div class="py-1" v-if="canChangeNetworkInterface">
              <b-form>
                <b-form-group
                  label="Interface"
                  label-for="networkInterfaceForm.interface"
                >
                  <b-form-select
                    id="networkInterfaceForm.interface"
                    name="networkInterfaceForm.interface"
                    v-model="$v.networkInterfaceForm.interface.$model"
                    :options="this.interfaceOptions"
                    :state="
                      validateState($v.networkInterfaceForm.interface.$model)
                    "
                    aria-describedby="networkInterfaceForm.interface.feedback"
                    @input="handleNetworkChange"
                  ></b-form-select>

                  <b-form-invalid-feedback
                    id="networkInterfaceForm.interface.feedback"
                    >Please enter a valid interface.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Static IP"
                  label-for="networkInterfaceForm.ip"
                >
                  <b-form-input
                    id="networkInterfaceForm.ip"
                    name="networkInterfaceForm.ip"
                    v-model="$v.networkInterfaceForm.ip.$model"
                    :state="validateState($v.networkInterfaceForm.ip)"
                    aria-describedby="networkInterfaceForm.ip.feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="networkInterfaceForm.ip.feedback"
                    >Please enter a valid IP.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Netmask"
                  label-for="networkInterfaceForm.netmask"
                >
                  <b-form-input
                    id="networkInterfaceForm.netmask"
                    name="networkInterfaceForm.netmask"
                    v-model="$v.networkInterfaceForm.netmask.$model"
                    :state="validateState($v.networkInterfaceForm.netmask)"
                    aria-describedby="networkInterfaceForm.netmask.feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="networkInterfaceForm.netmask.feedback"
                    >Please enter a valid netmask.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Gateway IP"
                  label-for="networkInterfaceForm.gateway"
                >
                  <b-form-input
                    id="networkInterfaceForm.gateway"
                    name="networkInterfaceForm.gateway"
                    v-model="$v.networkInterfaceForm.gateway.$model"
                    :state="validateState($v.networkInterfaceForm.gateway)"
                    aria-describedby="networkInterfaceForm.gateway.feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="networkInterfaceForm.gateway.feedback"
                    >Please enter a valid gateway.
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--                <b-form-group-->
                <!--                  label="Nameservers"-->
                <!--                  label-for="networkInterfaceForm.nameservers"-->
                <!--                >-->
                <!--                  <b-form-tags-->
                <!--                    id="networkInterfaceForm.nameservers"-->
                <!--                    name="networkInterfaceForm.nameservers"-->
                <!--                    v-model="$v.networkInterfaceForm.nameservers.$model"-->
                <!--                    :state="validateState($v.networkInterfaceForm.nameservers)"-->
                <!--                    aria-describedby="networkInterfaceForm.nameservers.feedback networkInterfaceForm.nameservers.feedback.help"-->
                <!--                    no-add-on-enter-->
                <!--                    placeholder="Enter nameserver"-->
                <!--                    :tag-validator="tagValidator"-->
                <!--                  >-->
                <!--                  </b-form-tags>-->

                <!--                  <b-form-invalid-feedback-->
                <!--                    id="networkInterfaceForm.nameservers.feedback"-->
                <!--                    >Please enter a valid nameservers.-->
                <!--                  </b-form-invalid-feedback>-->
                <!--                </b-form-group>-->

                <b-button
                  variant="primary"
                  @click="onSubmitNetworkInterfaceForm"
                  ><i class="fas fa-edit mr-1"></i>Update</b-button
                >
              </b-form>
            </div>
            <div class="py-1 text-muted" v-else>
              This setting is not available.
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <!-- network countdown -->
    <b-modal
      id="countdown-modal"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :no-enforce-focus="true"
      :centered="true"
      :cancel-disabled="true"
      :hide-header="true"
      :hide-footer="true"
    >
      <div class="text-center">
        <strong
          >You will be redirect to {{ networkInterfaceForm.ip }} in
          {{ countdownCounter }} seconds</strong
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  ipAddress,
  numeric,
  helpers
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

const alpha = helpers.regex("alpha", /,\s/);

export default {
  mixins: [validationMixin],
  data() {
    return {
      canChangeNetworkInterface: false,

      networkInterface: null,
      networkInterfaceForm: {
        interface: null,
        ip: null,
        netmask: null,
        gateway: null
        //nameservers: null
      },
      interfaceOptions: [],
      countdownCounter: 5
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  validations: {
    networkInterfaceForm: {
      interface: { required },
      ip: { required, ipAddress },
      netmask: { required, numeric },
      gateway: { required, ipAddress }
      //nameservers: { required }
    }
  },
  mounted: function () {
    this.getNetworkInterface();
    this.getAllSettings();
  },
  methods: {
    /** Settings **/
    async getAllSettings() {
      try {
        let $this = this;
        let objectServiceSetting = {};
        let objectSystemSetting = {};
        let objectPublic = {};
        let objectResult = {};

        let API_URL = this.getAPIServerURL + "/settings/all/";
        const client = this.$root.getAjaxFetchClient();
        let response = await client.getRequest(API_URL);

        response.result;

        if (response.result && response.result.length === 0) {
          /// create services settings

          objectPublic["public"] = $this.public;
          objectServiceSetting["service_setting"] = objectPublic;
          objectSystemSetting["system_setting"] = null;

          objectResult = {
            ...objectServiceSetting,
            ...objectSystemSetting
          };

          this.createSetting(objectResult);
        } else {
          $this.setting = response.result[0];

          if ($this.setting.service_setting.public != undefined) {
            if (
              Object.keys($this.setting.service_setting.public).length === 0
            ) {
              $this.setting.service_setting.public = $this.public;

              this.editSetting($this.setting);
            } else if (
              Object.keys($this.setting.service_setting.public).length <
              Object.keys($this.public).length
            ) {
              let array1 = Object.keys($this.setting.service_setting.public);
              let array2 = Object.keys($this.public);

              let difference = array2.filter((x) => !array1.includes(x));

              var result = {};
              for (var i = 0; i < difference.length; i++) {
                result[difference[i]] = false;
              }

              $this.setting.service_setting.public = {
                ...$this.setting.service_setting.public,
                ...result
              };

              this.editSetting($this.setting);
            } else {
              $this.showSetting = $this.setting.service_setting.public;

              // ** preferences ** //
              for (let key3 in $this.vmsPreferencesSettingTableItems) {
                if (
                  $this.vmsPreferencesSettingTableItems[key3].category ==
                  "Tolerance For Start Time (Min)"
                ) {
                  $this.vmsPreferencesSettingTableItems[key3].setting =
                    $this.showSetting.vms_preferences_tolerance_for_start_time;
                }
                if (
                  $this.vmsPreferencesSettingTableItems[key3].category ==
                  "Tolerance For End Time (Min)"
                ) {
                  $this.vmsPreferencesSettingTableItems[key3].setting =
                    $this.showSetting.vms_preferences_tolerance_for_end_time;
                }
              }
            }
          } else {
            $this.setting.service_setting["public"] = $this.public;

            this.editSetting($this.setting);
          }
        }
      } catch (err) {
        console.log(err);
        // let message = {
        //   message_text: err.toString(),
        //   message_type: "danger"
        // };
        // await this.$store.dispatch("session/addGlobalAlertMessage", message);
      }
    },
    async getNetworkInterface() {
      try {
        let API_URL = this.getAPIServerURL + "/settings/network-interface/";
        const client = this.$root.getAjaxFetchClient();
        let response = await client.getRequest(API_URL);

        if (response.result != null) {
          this.canChangeNetworkInterface = true;
          this.networkInterface = response.result;

          let index = 0;

          this.interfaceOptions = [];

          for (let interfaceKey in this.networkInterface) {
            this.interfaceOptions.push({
              text: interfaceKey,
              value: interfaceKey
            });

            if (index === 0) {
              this.networkInterfaceForm.interface = interfaceKey;

              let temp = this.networkInterface[interfaceKey]["addresses"][0];
              this.networkInterfaceForm.ip = temp.split("/")[0];
              this.networkInterfaceForm.netmask = temp.split("/")[1];
              this.networkInterfaceForm.gateway =
                this.networkInterface[interfaceKey]["gateway4"];

              this.networkInterfaceForm.nameservers =
                this.networkInterface[interfaceKey]["nameservers"]["addresses"];

              // this.networkInterfaceForm.ip = "temp.split('/')[0]";
              // this.networkInterfaceForm.netmask = "temp.split('/')[1]";
            }
            index++;
          }

          // this.interfaceOptions.push({text: 'haha', value: 'haha'});
          // this.networkInterfaceForm.interface = 'haha';
        }
      } catch (err) {
        console.log("get network-interface err");
        console.log(err);
        console.log(err.toString());

        await this.$store.dispatch("session/addGlobalAlertMessage", {
          message_text: "Invalid network-interface.",
          message_type: "danger"
        });
      }
    },

    handleNetworkChange(interfaceKey) {
      this.networkInterfaceForm.interface = interfaceKey;

      let temp = this.networkInterface[interfaceKey]["addresses"][0];
      this.networkInterfaceForm.ip = temp.split("/")[0];
      this.networkInterfaceForm.netmask = temp.split("/")[1];
      this.networkInterfaceForm.gateway =
        this.networkInterface[interfaceKey]["gateway4"];

      this.networkInterfaceForm.nameservers =
        this.networkInterface[interfaceKey]["nameservers"]["addresses"];
    },
    validateState(field) {
      const { $dirty, $error } = field;
      return $dirty ? !$error : null;
    },
    onSubmitNetworkInterfaceForm() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you confirm want to update system ip address? You will be auto redirect to new ip after do the changes.",
          {
            centered: true,
            okTitle: "Yes",
            cancelTitle: "No",
            okVariant: "primary",
            cancelVariant: "secondary"
          }
        )
        .then((value) => {
          if (value) {
            this.$v.networkInterfaceForm.$touch();
            if (this.$v.networkInterfaceForm.$anyError) {
              return;
            } else {
              try {
                let data = JSON.parse(
                  JSON.stringify(this.networkInterfaceForm)
                );

                let nameservers;
                for (
                  let i = 0;
                  i < this.networkInterfaceForm.nameservers.length;
                  i++
                ) {
                  if (i === 0) {
                    nameservers = this.networkInterfaceForm.nameservers[i];
                  } else {
                    nameservers += this.networkInterfaceForm.nameservers[i];
                  }

                  if (i !== this.networkInterfaceForm.nameservers.length - 1) {
                    nameservers += ", ";
                  }
                }

                data.nameservers = nameservers;

                this.updateNetworkInterface(data);
              } catch (err) {
                // console.log("Update network interface err");
                // console.log(err);

                let message = {
                  message_text: "Update network interface fail",
                  message_type: "danger"
                };
                this.$store.dispatch("session/addGlobalAlertMessage", message);
              }
            }
          }
        });
    },
    updateNetworkInterface(data) {
      let url = this.getAPIServerURL + "/settings/network-interface/";
      const client = this.$root.getAjaxFetchClient();

      let that = this;

      client
        .putRequest(url, data)
        .then((res) => {
          let message = {
            message_text: res.status_message,
            message_type: "success"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);

          // this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: "You will be redirect to new ip in 5 seconds",
          //   message_type: "warning"
          // });

          this.$bvModal.show("countdown-modal");

          this.countdownCounter = 5;

          setInterval(() => {
            this.countdownCounter -= 1;
          }, 1000);

          setTimeout(async () => {
            let apiserver_url;
            let wsserver_url;

            if (
              process.env.VUE_APP_APISERVER_USE_PORT !== undefined &&
              process.env.VUE_APP_APISERVER_USE_PORT !== null &&
              process.env.VUE_APP_APISERVER_USE_PORT === "true"
            ) {
              apiserver_url =
                window.location.protocol +
                "//" +
                window.location.hostname +
                ":8000";
            } else {
              apiserver_url =
                window.location.protocol +
                "//" +
                window.location.hostname +
                "/0";
            }

            wsserver_url = "ws://" + window.location.hostname;

            await that.$store.dispatch(
              "session/setAPIServerURL",
              apiserver_url
            );
            await localStorage.setItem("apiserverURL", apiserver_url);

            await that.$store.dispatch("session/setWSServerURL", wsserver_url);

            await localStorage.setItem("wsserverURL", wsserver_url);

            window.location.href = window.location.protocol + "//" + data.ip;
          }, 5000);
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },

    createSetting(data) {
      let url = this.getAPIServerURL + "/settings/";
      const client = this.$root.getAjaxFetchClient();

      client
        .postRequest(url, data)
        .then(() => {
          this.getAllSettings();
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    editSetting(data) {
      let url = this.getAPIServerURL + "/settings/?uid=" + data.id;

      const client = this.$root.getAjaxFetchClient();
      client
        .putRequest(url, data)
        .then(async () => {
          await this.getAllSettings();

          let message = {
            message_text: "Success update settings.",
            message_type: "success"
          };

          await this.$store.dispatch("session/addGlobalAlertMessage", message);

          if (
            this.getAPIServerURL !== data.service_setting.apiserver_url ||
            this.getWSServerURL !== data.service_setting.websocket_url
          ) {
            this.updateApiserverURLAndWsserverURL(
              data.service_setting.apiserver_url,
              data.service_setting.websocket_url
            );

            location.reload();
          }
        })
        .catch((err) => {
          let message = {
            message_text: err.toString(),
            message_type: "danger"
          };
          this.$store.dispatch("session/addGlobalAlertMessage", message);
        });
    },
    updateApiserverURLAndWsserverURL(apiserverURL, wsserverURL) {
      this.$store.dispatch("session/setAPIServerURL", apiserverURL);
      localStorage.setItem("apiserverURL", apiserverURL);

      this.$store.dispatch("session/setWSServerURL", wsserverURL);
      localStorage.setItem("wsserverURL", wsserverURL);
    }
  }
};
</script>

<style lang="scss">
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>

<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between">
      <h1>{{ pageTitle }}</h1>

      <div class="d-flex align-items-center">
        <h5 class="mt-1 mr-2"><i class="fas fa-sun"></i></h5>
        <b-form-checkbox switch size="lg" v-model="darkMode">
          <i class="fas fa-cloud-moon"></i>
        </b-form-checkbox>
      </div>
    </div>

    <IPAddressSetting></IPAddressSetting>
    <P1Setting></P1Setting>
    <EmailSetting></EmailSetting>
    <SmartCanopySetting></SmartCanopySetting>
    <LicenseSetting></LicenseSetting>

    <!-- <div class="accordion" role="tablist">
      <b-card no-body class="mt-3">
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.ssl class="d-flex justify-content-between">
            <div class="h6">
              <strong> SSL Setting </strong>
            </div>

            <span class="when-opened">
              <i class="fa fa-chevron-down" aria-hidden="true"></i
            ></span>
            <span class="when-closed">
              <i class="fa fa-chevron-up" aria-hidden="true"></i
            ></span>
          </div>
        </b-card-header>
        <b-collapse id="ssl" role="tabpanel">
          <b-card-body>
            <div class="py-1 text-muted">This setting is not available.</div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IPAddressSetting from "@/components/Settings/IPAddressSetting";
import P1Setting from "@/components/Settings/P1Setting";
import EmailSetting from "@/components/Settings/EmailSetting";
import SmartCanopySetting from "@/components/Settings/SmartCanopySetting";
import LicenseSetting from "@/components/Settings/LicenseSetting";

export default {
  components: {
    IPAddressSetting,
    P1Setting,
    EmailSetting,
    SmartCanopySetting,
    LicenseSetting
  },
  data() {
    return {
      pageTitle: "System Settings",
      darkMode: false
    };
  },
  mounted() {
    let htmlElement = document.documentElement;
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      htmlElement.setAttribute("data-theme", "dark");
      this.darkMode = true;
    } else {
      htmlElement.setAttribute("data-theme", "light");
      this.darkMode = false;
    }
  },
  computed: {
    ...mapGetters({
      getAPIServerURL: "session/getAPIServerURL"
    })
  },

  watch: {
    darkMode: function () {
      let htmlElement = document.documentElement;
      if (this.darkMode) {
        localStorage.setItem("theme", "dark");
        htmlElement.setAttribute("data-theme", "dark");
      } else {
        localStorage.setItem("theme", "light");
        htmlElement.setAttribute("data-theme", "light");
      }
    }
  },
  methods: {
    tagValidator(tag) {
      return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        tag
      );
    }
  }
};
</script>

<style lang="scss">
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
